import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Heading, HorizontalProgress, HorizontalProgressItem, Separator } from 'telia-front-react';

import { useStores } from '../stores/RootStore';

import PaymentMethods from './PaymentMethods';
import PrepaidDataComponent from './PrepaidDataComponent';
import PriceSelector from './PriceSelector';

const PrePaymentComponent = observer(() => {
  const { prepaidDataStore, priceStore, routeStore } = useStores();
  const translate = React.useContext(TranslationsContext).translate;
  const showHeading = React.useMemo(() => routeStore.queryParam('headless') !== 'true', [routeStore]);

  const getStepState = (index: number): 'done' | 'current' | 'disabled' => {
    if (index < stepIndex()) return 'done';
    else if (index === stepIndex()) return 'current';
    else return 'disabled';
  };

  const stepIndex = (): number => {
    if (priceStore.isStepDone) return 2;
    else if (prepaidDataStore.isStepDone) return 1;
    else return 0;
  };

  return (
    <>
      {showHeading && <Heading element="h3">{translate('prepaid.title')}</Heading>}
      <Separator spacing="xxsmall" color="invisible" />
      <HorizontalProgress break="md">
        <HorizontalProgressItem title={translate('prepaid.step.number.title')} state={getStepState(0)}>
          <PrepaidDataComponent disabled={stepIndex() !== 0} />
        </HorizontalProgressItem>
        <HorizontalProgressItem title={translate('prepaid.step.price.title')} state={getStepState(1)}>
          <PriceSelector disabled={stepIndex() == 0} />
        </HorizontalProgressItem>
        <HorizontalProgressItem title={translate('prepaid.step.bank.title')} state={getStepState(2)}>
          <PaymentMethods disabled={stepIndex() !== 2} />
        </HorizontalProgressItem>
      </HorizontalProgress>
      <Separator color="invisible" spacing="xxsmall" />
    </>
  );
});

export default PrePaymentComponent;
