import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Grid, GridColumn, Heading, List, ListItem, Separator, Text } from 'telia-front-react';

import { PricePlanQuota } from '../stores/models/IPrices';
import { useStores } from '../stores/RootStore';
import { formatDecimal } from '../utils/NumberUtil';

const Quotas = observer(() => {
  const { priceStore } = useStores();
  const translate = React.useContext(TranslationsContext).translate;

  if (!priceStore.selectedPrice?.quotas) {
    return null;
  }

  const getQuotaDescription = (quota: PricePlanQuota) => (
    <GridColumn width={quota.value ? ['xs-8'] : ['md-12']}>
      <Text>{translate(`prepaid.priceplan.quota.type.${quota.type.toLowerCase()}.${quota.unit.toLowerCase()}`)}</Text>
    </GridColumn>
  );

  const getQuotaValue = (quota: PricePlanQuota) => (
    <GridColumn width={['xs-4']} align={['middle-xs']} className="text-right">
      <Text className="text-bold">
        {formatDecimal(quota.value)} {translate(`prepaid.priceplan.quota.unit.${quota.unit.toLowerCase()}`)}
      </Text>
    </GridColumn>
  );

  const getQuotaItem = (quota: PricePlanQuota, index: number) => (
    <ListItem key={index}>
      <Grid>
        {getQuotaDescription(quota)}
        {quota.value && getQuotaValue(quota)}
      </Grid>
    </ListItem>
  );

  const getQuotaList = () => <List fullWidth>{priceStore.selectedPrice.quotas.map((quota: PricePlanQuota, index) => getQuotaItem(quota, index))}</List>;

  return (
    <>
      <Separator spacing="small" color="invisible" />
      <Heading level={5} className="text-bold">
        {translate(`prepaid.priceplan.title.${priceStore.selectedPrice.code}`)}
      </Heading>
      <Separator spacing="xsmall" color="invisible" />
      {getQuotaList()}
    </>
  );
});

export default Quotas;
