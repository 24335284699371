/*
    Remove spaces and +372 if present
 */
export function parsedNumber(phoneNumber: string): string {
  return phoneNumber
    .replace(' ', '')
    .replace(/^\+372/, '')
    .replace(/^372/, '');
}

/* 
    Make sure the price ends with .XX because it's billing API needs it.
 */
export function formatPrice(price: string): string {
  const parts = price.toString().split('.');

  if (parts.length === 1) {
    return `${price}.00`;
  } else if (parts[1].length < 2) {
    return `${price}0`;
  } else {
    return price;
  }
}

/*
    Formats a decimal number to use a comma instead of a dot.
*/
export function formatDecimal(value: number): string {
  if (value === null || value === undefined || isNaN(value)) {
    return '';
  }
  return value.toString().replace('.', ',');
}
