import { makeAutoObservable } from 'mobx';

import { QueryParam } from '../Constants';
import { RouteService } from '../services/RouteService';
import BrandUtil from '../utils/BrandUtil';

import EmailStore from './EmailStore';
import { ILanguageStore } from './LanguageStore';
import { PrepaidBrand } from './models/IPrepaidInfo';
import PrepaidDataStore from './PrepaidDataStore';
import PriceStore from './PriceStore';
import { IRouteStore } from './RouteStore';

class PaymentMethodsStore {
  constructor(
    private routeStore: IRouteStore,
    private priceStore: PriceStore,
    private emailStore: EmailStore,
    private routeService: RouteService,
    private languageStore: ILanguageStore,
    private prepaidDataStore: PrepaidDataStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get paymentMethods(): string[] {
    if (this.paymentSource === PrepaidBrand.PRION) return ['banklinks'];
    return ['card', 'banklinks'];
  }

  get transactionReference(): string {
    return this.routeStore.queryParam(QueryParam.CardTransactionReference);
  }

  get referenceNumber(): string {
    return this.prepaidDataStore.referenceNumber;
  }

  get paymentAmount(): number {
    return (this.priceStore.selectedPrice && Number(this.priceStore.selectedPrice.price)) || 0;
  }

  get paymentSource(): PrepaidBrand {
    return this.prepaidDataStore.brand;
  }

  get language(): Language {
    return this.languageStore.currentLanguage;
  }

  get brand(): Brand {
    return BrandUtil.getBrandType(this.routeStore.queryParam(QueryParam.Brand));
  }

  get bankLinkPaymentProps(): any {
    return {
      successUrl: this.routeStore.queryParam('successUrl') || this.routeService.createAbsoluteUrlWithCurrentParams('/payment-success'),
      failedUrl: this.routeStore.queryParam('cancelledUrl') || this.routeService.createAbsoluteUrlWithCurrentParams('/payment-cancelled'),
      processingUrl: this.routeStore.queryParam('processingUrl') || this.routeService.createAbsoluteUrlWithCurrentParams('/payment-processing')
    };
  }

  get paymentOrderParams(): {
    [key: string]: any;
  } {
    return this.emailStore.orderEmail ? { invoiceEmail: this.emailStore.orderEmail } : {};
  }

  get returnUrl(): string {
    return this.routeStore.queryParam('returnUrl');
  }

  public goToPaymentStatus = (status: string) => {
    let redirectStatus: PaymentStatus = 'cancelled';
    if (status === 'COMPLETED') redirectStatus = 'success';
    this.routeService.goToStatusUrl(redirectStatus);
  };
}

export default PaymentMethodsStore;
