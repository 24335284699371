import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid, GridColumn, Selector, Separator, Spinner, useCurrentBreakPoint, useTheme } from 'telia-front-react';

import { IPrice } from '../stores/models/IPrices';
import { useStores } from '../stores/RootStore';

import Quotas from './Quotas';

interface IPriceSelectorProps {
  disabled: boolean;
}

const PriceSelector = observer((props: IPriceSelectorProps) => {
  const { priceStore, prepaidDataStore } = useStores();
  const translate = React.useContext(TranslationsContext).translate;
  const { breakPoints } = useTheme();
  const currentBreak = useCurrentBreakPoint(breakPoints);
  const isMobile = currentBreak === 'xs' || currentBreak === 'sm';

  React.useEffect(() => {
    if (isMobile) {
      priceStore.setCanProceedToPayment(false);
    }
  }, [priceStore, isMobile]);

  const getContinueButton = () => (
    <Grid>
      <GridColumn className="text-center">
        <Button block size="large" iconRight="#arrow-right" text={translate('prepaid.steps.next')} onClick={() => priceStore.setCanProceedToPayment(true)} />
      </GridColumn>
    </Grid>
  );

  const getSelectorItems = (): any[] => {
    return priceStore.priceItems.map((price: IPrice) => ({
      id: `${price.code}`,
      label: `${parseInt(price.price)} €`,
      value: `${price.code}`,
      name: `${price.name}`,
      disabled: props.disabled
    }));
  };

  if (!priceStore.priceItems) {
    return null;
  }

  if (prepaidDataStore.loading) {
    return <Spinner />;
  }

  return (
    <>
      <Selector
        id="price-selector"
        size="xlarge"
        label={translate('prepaid.step.price.title')}
        hideLabel={true}
        items={getSelectorItems()}
        value={(priceStore.selectedPrice && priceStore.selectedPrice.code) || null}
        onChange={priceStore.updatePrice}
        itemWidth={['xs-4', 'xl-3']}
      />
      {priceStore.selectedPrice?.quotas && (
        <>
          <Quotas />
          <Separator spacing="xsmall" color="invisible" />
          {isMobile && getContinueButton()}
        </>
      )}
    </>
  );
});

export default PriceSelector;
